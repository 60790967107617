/********************************************************************************
Filename: np-img-cycle.js
Created: 20 Sep 2021
Author: Ron Robertson
Description: Cycles between displaying two images.
Attributes:
cycletime: number of seconds to wait for images to change. [Default:3]
resumetime: number of seconds to resume cycle after interaction. [Default:20]
buttonposition: The side the buttons should be displayed on. [Default:"left"]
Usage Example:
<np-img-cycle cycletime="5" resumetime="15" displayed="active"></np-img-cycle>
********************************************************************************/

(function() {
    const template = document.createElement('template');

    template.innerHTML = `
        <style>
            #imgCycle{
                position:relative;
                display:grid;
                justify-items:space-between;

            }
            .cycleImages{
                position: relative;
                display:grid;
                justify-items:center;
                align-items: flex-end;

            }
            .img1Displayed .img1Wrapper{
                animation-name: fade-in;
                opacity:0;
            }
            .img1Displayed .img2Wrapper{
                animation-name: fade-out;
                opacity: 1;
            }
            .img1Displayed .img3Wrapper{
                animation-name: fade-out;
                opacity: 1;
            }
            .img2Displayed .img1Wrapper{
                animation-name: fade-out;
                opacity:1;
            }
            .img2Displayed .img2Wrapper{
                animation-name: fade-in;
                opacity:0;
            }
            .img2Displayed .img3Wrapper{
                animation-name: fade-out;
                opacity:1;
            }
            .img3Displayed .img1Wrapper{
                animation-name: fade-out;
                opacity:1;
            }
            .img3Displayed .img2Wrapper{
                animation-name: fade-out;
                opacity:1;
            }
            .img3Displayed .img3Wrapper{
                animation-name: fade-in;
                opacity:0;
            }

            .interactive a{
                border: 1px solid #41B6E6;
                color: #41B6E6;
            }

            .img1Displayed .interactive .img1Trigger,
            .img2Displayed .interactive .img2Trigger,
            .img3Displayed .interactive .img3Trigger {
                background-color: #41B6E6;
                color: #fff;
            }

            a {
                text-decoration:none;
                text-align: center;
                line-height: 42px;
                display: inline-block;
                border-radius: 4px;
                padding:3px 6px;
                clear:both;
                margin-bottom: 16px;
                height: 42px;
                transition: all 0.5s ease-in-out;
                min-width:82px;
                font-size: var(--bodyFontSize, 17px);

            }
            .img1Wrapper,
            .img2Wrapper,
            .img3Wrapper {
                grid-column:1;
                grid-row:1;
                animation-fill-mode: forwards;
                animation-timing-function: ease-in-out;
                animation-duration: 0.5s;
            }
            .hide{
                display: none;
            }
            .interactive {
                position:absolute;
                top:0px;
                display:flex;
                flex-direction:column;
            }
            .interactive.left{
                left:0px;
            }
            .interactive.right{
                right:0px;
            }
            .interactive.below {
                position: relative;
                flex-direction: row;
                margin-top: 20px;
                justify-content: center;
            }
            .interactive.below a{
                border-radius: 0;
                border-left:0;
                border-right:0;
            }
            .interactive.below a:first-child {
                border-radius:4px 0 0 4px;
                border-left: 1px solid #41B6E6;
                border-right: 1px solid #41B6E6;
            }
            .interactive.below a:last-child {
                border-radius:0 4px 4px 0;
                border-left: 1px solid #41B6E6;
                border-right: 1px solid #41B6E6;
            }
            ::slotted(img){
                /*
                position: absolute;
                bottom:0px;
                left:0px;
                */
                vertical-align: bottom;
            }

            .caption{
                margin-top: 10px;
                font-size: var(--captionFontSize);
            }


            /** Theme pillButtons **/
                .pillButtons #interactiveButtons {
                    flex-direction: column;
                }
                /* small-up */
                @media (min-width: 480px) {
                    .pillButtons #interactiveButtons {
                        flex-direction: row;
                    }
                    .pillButtons #interactiveButtons a {
                        width: 220px;
                        max-width: 50%;
                        white-space: nowrap;
                    }
                    .pillButtons #interactiveButtons a:nth-child(1) {
                        border-radius: 4px 0 0 4px;

                    }
                    .pillButtons #interactiveButtons a:nth-child(2) {
                        border-radius: 0 4px 4px 0;
                    }
                }

            /** Theme activePassive **/
            .activePassive .interactive .img1Trigger {
                border: 1px solid #E33610;
                color: #E33610;
            }
            .activePassive.img1Displayed .interactive .img1Trigger{
                background-color: #E33610;
                color: #fff;
            }


            @keyframes fade-in {
                0% {
                    opacity:0;
                }
                100% {
                    opacity:1;
                }
            }
            @keyframes fade-out {
                0% {
                    opacity:1;

                }
                100% {
                    opacity:0;
                }
            }
            @media screen and (min-width: 1200px) {
                #imgCycle {
                    --bodyFontSize: 17px;
                }
            }


        </style>

        <div id="imgCycle" class="img2Displayed">
            <div id="cycleImages" class="cycleImages">
                <span class="img1Wrapper">
                    <slot name="img1"></slot>
                </span>
                <span class="img2Wrapper">
                    <slot name="img2"></slot>
                </span>
                <span id="img3Wrapper" class="img3Wrapper hide">
                    <slot name="img3"></slot>
                </span>
                <div id="caption" class="caption hide"></div>
            </div><!--END cycleImages-->
            <div id="interactiveButtons" class="interactive right">
                <a id="img1Trigger" class="img1Trigger" href="" onclick="return false"></a>
                <a id="img2Trigger" class="img2Trigger" href="" onclick="return false"></a>
                <a id="img3Trigger" class="img3Trigger hide" href="" onclick="return false"></a>
            </div><!--END interactive-->
        </div><!--END imgCycle-->
    `;

    class NpImgCycle extends HTMLElement {
        constructor() {
            /* Always call super first in constructor */
            super();

            /* bind 'this' to class in the method */
            this.startTrackingMethodCycle = this.startTrackingMethodCycle.bind(this);
            this.trackingMethodsCycle = this.trackingMethodsCycle.bind(this);
            this.trackingMethodDisplay = this.trackingMethodDisplay.bind(this);
            this.resetInterval = this.resetInterval.bind(this);
            this.triggerEvent = this.triggerEvent.bind(this);

            /* Use the Shadow DOM */
            this.attachShadow({ mode: 'open' });
            this.shadowRoot.appendChild(template.content.cloneNode(true));

            /* Reference to DOM Elements */
            this.imgCycle = this.shadowRoot.getElementById("imgCycle");
            this.captionEle = this.shadowRoot.getElementById("caption");
            this.img1Trigger = this.shadowRoot.getElementById("img1Trigger");
            this.img2Trigger = this.shadowRoot.getElementById("img2Trigger");
            this.img3Trigger = this.shadowRoot.getElementById("img3Trigger");
            this.img3Wrapper = this.shadowRoot.getElementById("img3Wrapper");
            this.interactiveButtons = this.shadowRoot.getElementById("interactiveButtons");

            //Interval used to track when to cycle
            this.trackingMethodsCycleInterval;

        } /* END constructor */

        /* Called when added to DOM */
        connectedCallback() {

            /* Set default attribute value */
            if (!this.hasAttribute('cycletime')) {
                this.setAttribute('cycletime', 3);
            }
            /* Set default attribute value */
            if (!this.hasAttribute('resumetime')) {
                this.setAttribute('resumetime', 20);
            }
            /* Set default attribute value */
            if (!this.hasAttribute('buttonposition')) {
                this.setAttribute('buttonposition', "left");
            }
            if (!this.hasAttribute('theme')) {
                this.setAttribute('theme', "motive");
            }
            if (!this.hasAttribute('trigger1label')) {
                this.setAttribute('trigger1label', "Active");
            }
            if (!this.hasAttribute('trigger2label')) {
                this.setAttribute('trigger2label', "Passive");
            }
            if (this.hasAttribute('trigger3label')) {
                this.img3Trigger.classList.remove('hide');
                this.img3Wrapper.classList.remove('hide');
            }

            /* Set Event Listeners */
            this.img1Trigger.addEventListener("mouseover", this.triggerEvent);
            this.img1Trigger.addEventListener("mouseout", this.triggerEvent);
            this.img2Trigger.addEventListener("mouseover", this.triggerEvent);
            this.img2Trigger.addEventListener("mouseout", this.triggerEvent);

            if(this.trigger3label != null) {
                this.img3Trigger.addEventListener("mouseover", this.triggerEvent);
                this.img3Trigger.addEventListener("mouseout", this.triggerEvent);
            }

            //start cycling the display
            this.startTrackingMethodCycle(0);

        } /* END connectedCallback */

        /* Listen for when attributes change */
        /* attributes must be lowercase */
        static get observedAttributes() {
            return ['cycletime','resumetime','buttonposition','theme','trigger1label','trigger2label','trigger3label', 'caption'];
        }

      /* Called when attributes change */
      attributeChangedCallback(name, oldValue, newValue) {

        if(name === 'buttonposition') {

            if(newValue === 'right'){
                this.interactiveButtons.classList.remove('left');
                this.interactiveButtons.classList.add('right');
            }
            if(newValue === 'left'){
                this.interactiveButtons.classList.remove('right');
                this.interactiveButtons.classList.add('left');
            }
            if(newValue === 'below'){
                this.interactiveButtons.classList.remove('left');
                this.interactiveButtons.classList.remove('right');
                this.interactiveButtons.classList.add('below');
            }

        }
        if(name === 'theme') {
            this.imgCycle.classList.remove(oldValue);
            this.imgCycle.classList.add(newValue);
        }
        if(name === 'trigger1label') {
            this.img1Trigger.innerHTML = newValue;
        }
        if(name === 'trigger2label') {
            this.img2Trigger.innerHTML = newValue;
        }
        if(name === 'trigger3label') {
            this.img3Trigger.innerHTML = newValue;
        }
        if(name === 'caption') {
            this.captionEle.classList.remove('hide');
            this.captionEle.innerHTML = newValue;
        }


       }/* END attributesChangedCallback */

      /* Getting attributes */
      get cycletime() {
        return this.getAttribute('cycletime');
      }
      get resumetime() {
        return this.getAttribute('resumetime');
      }
      get buttonposition() {
        return this.getAttribute('buttonposition');
      }
      get theme() {
        return this.getAttribute('theme');
      }
      get trigger1label() {
        return this.getAttribute('trigger1label');
      }
      get trigger2label() {
        return this.getAttribute('trigger2label');
      }
      get trigger3label() {
        return this.getAttribute('trigger3label');
      }
      get caption() {
        return this.getAttribute('caption');
      }

      /* Settinng attributes */
      set cycletime(newValue) {
        this.setAttribute('cycletime', newValue);
      }
      set resumetime(newValue) {
        this.setAttribute('resumetime', newValue);
      }
      set buttonposition(newValue) {
        this.setAttribute('buttonposition', newValue);
      }
      set theme(newValue) {
        this.setAttribute('theme', newValue);
      }
      set trigger1label(newValue) {
        this.setAttribute('trigger1label', newValue);
      }
      set trigger2label(newValue) {
        this.setAttribute('trigger2label', newValue);
      }
      set trigger3label(newValue) {
        this.setAttribute('trigger3label', newValue);
      }
      set caption(newValue) {
        this.setAttribute('caption', newValue);
      }


      /* Called when removed from DOM */
      disconnectedCallback() {

        /* Removing event listeners */
        this.img1Trigger.removeEventListener("mouseover", this.triggerEvent);
        this.img1Trigger.removeEventListener("mouseout", this.triggerEvent);
        this.img2Trigger.removeEventListener("mouseover", this.triggerEvent);
        this.img2Trigger.removeEventListener("mouseout", this.triggerEvent);
        this.img3Trigger.removeEventListener("mouseover", this.triggerEvent);
        this.img3Trigger.removeEventListener("mouseout", this.triggerEvent);

        //Removing interval
        clearInterval(this.trackingMethodsCycleInterval);
      }

        /* Method */
        startTrackingMethodCycle(secondsToDelay) {
            clearInterval(this.trackingMethodsCycleInterval);

            /* Delays before starting cycle */
            setTimeout(this.resetInterval, secondsToDelay * 1000);
        }

        resetInterval(){
            /* Cycle every [cycletime] seconds */
            clearInterval(this.trackingMethodsCycleInterval);
            this.trackingMethodsCycleInterval = setInterval(this.trackingMethodsCycle, (this.cycletime * 1000));
        }

        trackingMethodsCycle() {
            if(this.imgCycle.classList.contains('img1Displayed')) {
                this.imgCycle.classList.remove('img1Displayed');
                this.imgCycle.classList.add('img2Displayed');
            } else if(this.imgCycle.classList.contains('img2Displayed')) {
                this.imgCycle.classList.remove('img2Displayed');
                if(this.hasAttribute('trigger3label')){
                    this.imgCycle.classList.add('img3Displayed');
                    console.log('adding img3Displayed');
                }else{
                    this.imgCycle.classList.add('img1Displayed');
                }
            } else if(this.imgCycle.classList.contains('img3Displayed')) {
                this.imgCycle.classList.remove('img3Displayed');
                this.imgCycle.classList.add('img1Displayed');
            }
        }

        trackingMethodDisplay(method) {
            clearInterval(this.trackingMethodsCycleInterval);
            if(method=='img1') {
                this.imgCycle.classList.remove('img2Displayed');
                this.imgCycle.classList.remove('img3Displayed');
                this.imgCycle.classList.add('img1Displayed');
            } else if(method=='img2') {
                this.imgCycle.classList.remove('img1Displayed');
                this.imgCycle.classList.remove('img3Displayed');
                this.imgCycle.classList.add('img2Displayed');
            } else if(method=='img3') {
                this.imgCycle.classList.remove('img1Displayed');
                this.imgCycle.classList.remove('img2Displayed');
                this.imgCycle.classList.add('img3Displayed');
            }
        }
        triggerEvent(e) {
            if(e.target.id === "img2Trigger"){
                this.trackingMethodDisplay("img2");
                this.startTrackingMethodCycle(this.resumetime);
            }else if(e.target.id === "img1Trigger"){
                this.trackingMethodDisplay("img1");
                this.startTrackingMethodCycle(this.resumetime);
            }else if(e.target.id === "img3Trigger"){
                this.trackingMethodDisplay("img3");
                this.startTrackingMethodCycle(this.resumetime);
            }
        }
    } /* END class */

    window.customElements.define('np-img-cycle', NpImgCycle);

  })();
